import React, { useEffect, useState, useContext } from "react";
import Boost from "../../components/Boost";
import { initBackButton, useInitData } from "@telegram-apps/sdk-react";
import clickerLevels from "../../data/clickerLevels";
import energyLevels from "../../data/energyLevels";
import energyRecoveryLevels from "../../data/energyRecoveryLevels";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {TimerContext} from "../../context/TimerContext";
import Menu from "../../components/Menu";
import flipLevels from "../../data/flipLevels.js"; // Add this import
import useAppContext from '../../state/useAppContext';
import { getMaxEnergy } from "../../utils/getMaxEnergy";
const API_URL = process.env.REACT_APP_API_URL;

function Boosts() {
  const [user, setUser] = useState(null);
  const [clickerLevel, setClickerLevel] = useState(1);
  const [energyLevel, setEnergyLevel] = useState(1);
  const [energyRecoveryLevel, setEnergyRecoveryLevel] = useState(1);
  const [flipLevel, setFlipLevel] = useState(1);
  const initData = useInitData();
  const telegramUserId = initData.user.id;
  const userId = initData.user.id;
  const [backButton] = initBackButton();
  const navigate = useNavigate();
  const [boosts, setBoosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const { _id, id, score } = user || {};
  const { startTimer, startFlipCoin, isRunning, timeLeft } = useContext(TimerContext);
  const { actions } = useAppContext();
  const { setEnergy } = actions;

  console.log('user', user);
  const handleStart = () => {
    startTimer(15);
  };

  const handleStartShowFlipCoin = () => {
    startFlipCoin(3);
  };

  useEffect(() => {
    if (!telegramUserId) return;
    console.log("fetchUserData");
    const fetchUserData = async () => {
      try {
        const response = await axios.get(
          `${API_URL}/users/get-user/${telegramUserId}`,
        );
        const userData = response.data;

        if (!userData) {
          setLoading(false);
          return;
        }

        const { energyLevel, energyRecoveryLevel, clickLevel, flipLevel } = userData;

        setUser({ ...userData });
        setClickerLevel(clickLevel);
        setEnergyLevel(energyLevel);
        setEnergyRecoveryLevel(energyRecoveryLevel);
        setFlipLevel(flipLevel);
      } catch (error) {
        console.error("Error fetching user:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, [telegramUserId]);

  // Show back button and navigate on click
  backButton.on("click", () => {
    backButton.hide();
    navigate("/");
  });

  // Show back button on component mount
  useEffect(() => {
    backButton.show();
  }, [backButton]);

  // Fetch available boosts
  useEffect(() => {
    const fetchAvailableBoosts = async () => {
      try {
        const response = await axios.get(`${API_URL}/boosts/available/${_id}`);
        setBoosts(response.data.boosts);
      } catch (error) {
        console.error("Error fetching boosts:", error);
      } finally {
        setLoading(false);
      }
    };

    if (id) {
      fetchAvailableBoosts();
    }
  }, [id]);

  // Function to use a boost
  const handleUseBoost = async (boostId) => {
    try {
      const response = await axios.post(`${API_URL}/boosts/use`, {
        userId: _id,
        dailyBoostId: boostId,
      });

      if (response.data.success) {
        // Update boosts after using one
        setBoosts(
          boosts.map((boost) =>
            boost._id === boostId
              ? {
                  ...boost,
                  availableCount: boost.availableCount - 1,
                }
              : boost,
          ),
        );

        // Get the boost that was just used
        const usedBoost = boosts.find(boost => boost._id === boostId);
        if (usedBoost) {
          if (usedBoost.name === 'Turbo tap') {
            backButton.hide();
            navigate('/');
          } else if (usedBoost.name === 'Recharger') {
            const maxEnergy = getMaxEnergy(user.energyLevel); // Default to 100 if level not found
            setEnergy(maxEnergy);
            backButton.hide();
            navigate('/');
          }
        }
      }
    } catch (error) {
      console.error("Error using boost:", error);
    }
  };

  // Handle loading state
  if (loading || !userId) {
    return <div>Loading....</div>;
  }

  const isMaxClickerLevel = clickerLevel >= clickerLevels.length;
  const nextClickerLevelValue = isMaxClickerLevel
    ? "Max Level"
    : (clickerLevels[clickerLevel]?.value ?? Infinity);

  const isMaxEnergyLevel = energyLevel >= energyLevels.length;
  const nextEnergyLevelValue = isMaxEnergyLevel
    ? "Max Level"
    : (energyLevels[energyLevel]?.value ?? Infinity);

  const isMaxEnergyRecoveryLevel =
    energyRecoveryLevel >= energyRecoveryLevels.length;
  const nextEnergyRecoveryLevelValue = isMaxEnergyRecoveryLevel
    ? "Max Level"
    : (energyRecoveryLevels[energyRecoveryLevel]?.value ?? Infinity);

  const incrementClickerLevel = async () => {
    const newClickerLevel = clickerLevel + 1;
    if (user) {
      try {
        const response = await axios.post(`${API_URL}/boosts/increase-level`, {
          userId: user.id,
          boostType: "clicker",
          boostLevel: newClickerLevel,
        });
        setClickerLevel(newClickerLevel);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const incrementEnergyLevel = async () => {
    const newEnergyLevel = energyLevel + 1;
    if (user) {
      try {
        const response = await axios.post(`${API_URL}/boosts/increase-level`, {
          userId: user.id,
          boostType: "energy",
          boostLevel: newEnergyLevel,
        });
        setEnergyLevel(newEnergyLevel);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const incrementEnergyRecoveryLevel = async () => {
    const newEnergyRecoveryLevel = energyRecoveryLevel + 1;
    if (user) {
      try {
        const response = await axios.post(`${API_URL}/boosts/increase-level`, {
          userId: user.id,
          boostType: "energyRecovery",
          boostLevel: newEnergyRecoveryLevel,
        });
        setEnergyRecoveryLevel(newEnergyRecoveryLevel);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const isMaxFlipLevel = flipLevel >= flipLevels.length;
  const nextFlipLevelValue = isMaxFlipLevel
    ? "Max Level"
    : (flipLevels[flipLevel]?.value ?? Infinity);

  const incrementFlipLevel = async () => {
    const newFlipLevel = flipLevel + 1;
    if (user) {
      try {
        const response = await axios.post(`${API_URL}/boosts/increase-level`, {
          userId: user.id,
          boostType: "flip",
          boostLevel: newFlipLevel,
        });
        setFlipLevel(newFlipLevel);
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <div className="page">
    <div className="tasks-page">
      <h2 className="tasks-header">Free daily boosters</h2>
      <div className="booster-container">
        {boosts.map((boost) => (
          <div
            key={boost._id}
            className={`task-item ${boost.availableCount <= 0 ? "inactive" : ""}`}
            onClick={() => {
                if (boost.availableCount > 0) {
                    handleUseBoost(boost._id)
                    if (boost.name === 'Turbo tap') {
                        handleStartShowFlipCoin();
                    }
                    if (boost.name === 'Recharger') {
                       
                    }
                }
            }
            }
          >
            <span>💡</span>
            {boost.name} <br /> {boost.availableCount}/{boost.count}
          </div>
        ))}
      </div>
      <h2 className="tasks-header">Boosters</h2>
      <div className="boosts-list">
        <Boost
          title={"🔥 Clicker"}
          clickerLevel={clickerLevel}
          incrementLevel={incrementClickerLevel}
          isMaxLevel={isMaxClickerLevel}
          nextLevelValue={nextClickerLevelValue}
          score={score}
        />
        <Boost
          title={"🔥 Energy"}
          clickerLevel={energyLevel}
          incrementLevel={incrementEnergyLevel}
          isMaxLevel={isMaxEnergyLevel}
          nextLevelValue={nextEnergyLevelValue}
          score={score}
        />
        <Boost
          title={"🔥 Energy Recovery"}
          clickerLevel={energyRecoveryLevel}
          incrementLevel={incrementEnergyRecoveryLevel}
          isMaxLevel={isMaxEnergyRecoveryLevel}
          nextLevelValue={nextEnergyRecoveryLevelValue}
          score={score}
        />
        <Boost
          title={"🔥 Flip"}
          clickerLevel={flipLevel}
          incrementLevel={incrementFlipLevel}
          isMaxLevel={isMaxFlipLevel}
          nextLevelValue={nextFlipLevelValue}
          score={score}
        />
      </div>
    </div>
    <div className="menu-container">
        <Menu/>
    </div>
    </div>
  );
}

export default Boosts;
