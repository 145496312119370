import React, { useReducer, useEffect, useCallback, useRef, useContext } from 'react';
import { useTelegram } from '../hooks/useTelegram';
import {useInitData} from "@telegram-apps/sdk-react";
import AppContext from './AppContext'
import { appReducer, initialState } from './appReducer';
import * as usersApi from '../api/usersApi';
import * as tasksApi from '../api/tasksApi';
import * as boostsApi from '../api/boostsApi';
import axios from 'axios';
import energyLevels from '../data/energyLevels';
import energyRecoveryLevels from '../data/energyRecoveryLevels';
import {getMaxEnergy} from "../utils/getMaxEnergy";
import { useNavigate } from 'react-router-dom';
import { TimerContext } from '../context/TimerContext';

const API_URL = process.env.REACT_APP_API_URL;

export const AppProvider = ({ children }) => {
    console.log('AppProvider rendered');
    const navigate = useNavigate();
    const [state, dispatch] = useReducer(appReducer, initialState);
    //const { user: telegramUser } = useTelegram();
    const initData = useInitData()
    const telegramUser = initData.user;
    const timeoutRef = useRef(null);
    const { id, energy, energyLevel, energyRecoveryLevel } =  state.user || {};
    const maxEnergy = getMaxEnergy(energyLevel);
    const energyRecovery = energyRecoveryLevels[energyRecoveryLevel - 1]?.score || 1;
    const { isRunning } = useContext(TimerContext);

    const fetchUser = useCallback(async (telegramUserId) => {
        console.log('fetchUser', telegramUserId);
        if (!telegramUserId) return;
        
        // Check if user data already exists and is not empty
        if (state.user && Object.keys(state.user).length > 0) {
            return;
        }

        dispatch({ type: 'SET_LOADING', payload: true });
        try {
            const response = await axios.get(`${API_URL}/users/get-user/${telegramUserId}`);
            const userData = response.data;

            if (userData) {
                const { energy, energyLevel, energyRecoveryLevel, closeAppTime, score, league } = userData;
                const maxEnergy = energyLevels[energyLevel - 1]?.score || 0;
                const energyRecovery = energyRecoveryLevels[energyRecoveryLevel - 1]?.score || 1;
                let accumulatedEnergy = closeAppTime ? Math.floor((Date.now() - closeAppTime) * energyRecovery / 1000) : 0;
                const newEnergy = Math.min(energy + accumulatedEnergy, maxEnergy);
                const updatedUserData = { ...userData, energy: newEnergy };
                dispatch({ type: 'SET_USER', payload: updatedUserData });
            }
        } catch (error) {
            dispatch({ type: 'SET_IS_NEW_USER', payload: true });
            navigate('/welcome');
            //dispatch({ type: 'SET_ERROR', payload: error.message });
        } finally {
            dispatch({ type: 'SET_LOADING', payload: false });
        }
    }, [state.user, navigate]);

    const updateUser = useCallback(async (userData) => {
        if (!state.user) return;

        try {
            const updatedUser = await usersApi.updateUser({
                id: state.user.id,
                ...userData,
            });
            dispatch({ type: 'SET_USER', payload: updatedUser });
        } catch (error) {
            dispatch({ type: 'SET_ERROR', payload: error.message });
        }
    }, [state.user]);

    const createUser = useCallback(async (userData) => {
        try {
            const newUser = await usersApi.createUser(userData);
            dispatch({ type: 'SET_USER', payload: newUser });
        } catch (error) {
            dispatch({ type: 'SET_ERROR', payload: error.message });
        }
    }, []);

    const modifyEnergy = useCallback(async (energy) => {
        if (!state.user) return;

        dispatch({ type: 'MODIFY_ENERGY', payload: energy });

        try {
            // Optionally, update the backend
            await axios.post(`${API_URL}/users/update-user`, {
                id: state.user.id,
                energy: state.user.energy + energy,
                closeAppTime: Date.now(),
            });
        } catch (error) {
            console.error('Error updating energy:', error);
        }
    }, [state.user]);

    const setEnergy = useCallback(async (energy) => {
        if (!state.user) return;

        dispatch({ type: 'SET_ENERGY', payload: energy });

        try {
            // Optionally, update the backend
            await axios.post(`${API_URL}/users/update-user`, {
                id: state.user.id,
                energy: energy,
                closeAppTime: Date.now(),
            });
        } catch (error) {
            console.error('Error updating energy:', error);
        }
    }, [state.user]);

    const modifyScore = useCallback(async (score) => {
        const multiplier = isRunning ? 2 : 1; // Double the score during turbo mode
        const finalScore = score * multiplier;
        dispatch({ type: 'MODIFY_SCORE', payload: finalScore });
        try {
            await axios.post(`${API_URL}/users/update-user`, {
                id: state.user.id,
                score: state.user.score + finalScore,
                closeAppTime: Date.now(),
            });
        } catch (error) {
            console.error('Error updating score:', error);
        }
    }, [state.user, isRunning]);

    const fetchTasks = useCallback(async () => {
        dispatch({ type: 'SET_LOADING', payload: true });
        try {
            const tasks = await tasksApi.fetchTasks();
            dispatch({ type: 'SET_TASKS', payload: tasks });
        } catch (error) {
            dispatch({ type: 'SET_ERROR', payload: error.message });
        }
    }, []);

    const fetchBoosts = useCallback(async () => {
        dispatch({ type: 'SET_LOADING', payload: true });
        try {
            const boosts = await boostsApi.fetchBoosts();
            dispatch({ type: 'SET_BOOSTS', payload: boosts });
        } catch (error) {
            dispatch({ type: 'SET_ERROR', payload: error.message });
        }
    }, []);

    useEffect(() => {
        if (!telegramUser?.id) return;
        fetchUser(telegramUser.id);

    }, [telegramUser, fetchUser]);

    useEffect(() => {
        if (energy === undefined || energyRecovery === undefined || maxEnergy === undefined) {
            return;
        }

        const incrementEnergy = () => {
            clearTimeout(timeoutRef.current);
            timeoutRef.current = null;

            if (maxEnergy > energy) {
                if (energy + energyRecovery <= maxEnergy) {
                    dispatch({ type: 'MODIFY_ENERGY', payload: energyRecovery });
                } else {
                    dispatch({ type: 'SET_ENERGY', payload: maxEnergy });
                }
            }
        };

        // Clear the timeout if energy is at max
        if (energy >= maxEnergy) {
            clearTimeout(timeoutRef.current);
            timeoutRef.current = null;
            return;
        }

        if (!timeoutRef.current) {
            timeoutRef.current = setTimeout(incrementEnergy, 1000);
        }
    }, [energy, energyRecovery, maxEnergy, setEnergy]);

    // useEffect(() => {
    //     const nextLeague = leagues[league] ?? null;
    //     if (nextLeague) {
    //         dispatch({ type: 'SET_NEXT_LEAGUE', payload: nextLeague });
    //     }
    // }, [league]);

    if (state.isLoading) {
        return <div>Loading...</div>;
    }

    if (state.error) {
        return <div>Error: {state.error}</div>;
    }

    return (
        <AppContext.Provider value={{
            ...state,
            actions: {
                fetchUser,
                updateUser,
                modifyEnergy,
                modifyScore,
                setEnergy,
                fetchTasks,
                fetchBoosts,
                createUser,
            }
        }}>
            {children}
        </AppContext.Provider>
    );
};
