import { TonConnectButton, useTonWallet } from '@tonconnect/ui-react';
import {
  Avatar,
  Cell,
  List,
  Navigation,
  Placeholder, Section,
  Text,
  Title,
} from '@telegram-apps/telegram-ui';
import Menu from "../../components/Menu";
import { DisplayData } from '../../components/DisplayData/DisplayData.jsx';

import './TONConnectPage.css';

/**
 * @returns {JSX.Element}
 */
export function TONConnectPage() {
  const wallet = useTonWallet();
  if (!wallet) {
    return (
      <div className="page">
      <div className="friends-page">
        <Placeholder
          className='ton-connect-page__placeholder'
          header='TON Connect'
          description={
            <>
              <Text>
                To display the data related to the TON Connect, it is required to connect your wallet
              </Text>
              <TonConnectButton className='ton-connect-page__button'/>
            </>
          }
        />
      </div>
      <div className="menu-container">
        <Menu/>
      </div>
      </div>
    );
  }

  const {
    account: { chain, publicKey, address },
    device: {
      appName,
      appVersion,
      maxProtocolVersion,
      platform,
      features,
    },
  } = wallet;

  return (
    <div className="page">
      <div className="wallet-page">
        <List>
          {'imageUrl' in wallet && (
            <div className=''>
              <Section>
                <Cell
                  before={
                    <Avatar src={wallet.imageUrl} alt='Provider logo' width={10} height={10}/>
                  }
                  after={<Navigation>About wallet</Navigation>}
                  subtitle={wallet.appName}
                  onClick={(e) => {
                    e.preventDefault();
                    // If WebApp is not available, you may want to use window.open or another method
                    // WebApp.openLink(wallet.aboutUrl);
                    window.open(wallet.aboutUrl, '_blank');
                  }}
                >
                  <Title level='3'>{wallet.name}</Title>
                </Cell>
              </Section>
              <TonConnectButton className='ton-connect-page__button-connected'/>
            </div>
          )}
          {/* <DisplayData
            header='Account'
            rows={[
              { title: 'Address', value: address },
              { title: 'Chain', value: chain },
              { title: 'Public Key', value: publicKey },
            ]}
          />
          <DisplayData
            header='Device'
            rows={[
              { title: 'App Name', value: appName },
              { title: 'App Version', value: appVersion },
              { title: 'Max Protocol Version', value: maxProtocolVersion },
              { title: 'Platform', value: platform },
              {
                title: 'Features',
                value: features
                  .map(f => typeof f === 'object' ? f.name : undefined)
                  .filter(v => v)
                  .join(', '),
              },
            ]}
          /> */}
        </List>
      </div>
      <div className="menu-container">
        <Menu/>
      </div>
    </div>
  );
}
