import Home from "../pages/Home/Home";
import Boosts from "../pages/Boosts/Boosts";
import Welcome from "../pages/Welcome/Welcome";
import UserStartStats from "../pages/UserStartStats/UserStartStats";
import Congratulations from "../pages/Congratulations/Congratulations";
import Stats from "../pages/Stats/Stats";
import Friends from "../pages/Friends/Friends";
import Tasks from "../pages/Tasks/Tasks";
import {TONConnectPage} from "../pages/TONConnectPage/TONConnectPage";

export const routes = [
    {path: '/', Component: Home},
    {path: '/welcome', Component: Welcome},
    {path: '/user-start-stats', Component: UserStartStats},
    {path: '/congratulations', Component: Congratulations},
    {path: '/boosts', Component: Boosts},
    {path: '/friends', Component: Friends},
    {path: '/tasks', Component: Tasks},
    {path: '/wallet', Component: TONConnectPage},
];
