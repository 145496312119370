import React, {useEffect, useRef, useState, useContext} from 'react';
import Score from "../../components/Score";
import Energy from "../../components/Energy";
import {useNavigate} from 'react-router-dom';
import {useInitData} from "@telegram-apps/sdk-react";
import {useTelegram} from "../../hooks/useTelegram";
import Popups from "../../components/Popups";
import axios from 'axios';
import leagues from "../../data/leagues.";
import Coin from "../../components/Coin";
import {getRandomHapticFeedback} from "../../utils/getRandomHapticFeedback"
import {getPopups} from "../../utils/getPopups";
import { useHapticFeedback } from "@telegram-apps/sdk-react";
import League from "../../components/League";
import {TimerContext} from "../../context/TimerContext";
import useAppContext from '../../state/useAppContext';
import Menu from '../../components/Menu';
import FlipCoin from "../../components/FlipCoin";

const API_URL = process.env.REACT_APP_API_URL;

const Home = () => {
    const { user, actions, isLoading, error } = useAppContext();
    const {setEnergy, modifyEnergy, modifyScore, updateUser} = actions;
    const { username, energyLevel, energyRecoveryLevel, clickLevel, league, score, energy, flipLevel } = user || {};
    const navigate = useNavigate();
    const timeoutRefs = useRef([]);
    const coinRef = useRef();
    const [popups, setPopups] = useState([]);
    const hapticFeedback = useHapticFeedback();
    const { isRunning, showFlipCoin, isTurboRunning, startTurbo } = useContext(TimerContext);
    const [flipCoinPosition, setFlipCoinPosition] = useState({ top: 50, left: 50 });

    
    console.log('showFlipCoin', showFlipCoin);

    const synchronize = async () => {
        try {
            let newLeague = league;
            const nextLeague = leagues[league] ?? null;

            if (nextLeague && score >= nextLeague.score) {
                newLeague++;
            }

            const response = await axios.post(`${API_URL}/users/update-user`, {
                id: user.id,
                score: score,
                energy: energy,
                league: newLeague,
                closeAppTime: Date.now(),
            });

            console.log('User updated', response)
        } catch (error) {
            console.error('Error syncing state with DB', error);
        }
    };

    useEffect(() => {
        const nextLeague = leagues[league] ?? null;

        if (nextLeague && score >= nextLeague.score) {
            updateUser({
                league: league + 1
            });
        }
    }, [score, league, updateUser]);

    useEffect(() => {
        return () => {
            timeoutRefs.current.forEach(clearTimeout);
        };
    }, [])

    useEffect(() => {
        if (showFlipCoin) {
            generateRandomPosition();
        }
    }, [showFlipCoin]);

    useEffect(() => {
        if (isTurboRunning) {
            document.body.style.backgroundImage = 'url("assets/fire-background.jpg")';
            document.body.classList.add('flipcoin-active');
            const turboIntervalId = setInterval(() => {
                hapticFeedback.impactOccurred('medium');
            }, 500)
            return () => clearInterval(turboIntervalId);
        } else {
            document.body.style.backgroundImage = 'url("assets/background.jpg")';
            document.body.classList.remove('flipcoin-active');
        }
    }, [isTurboRunning, hapticFeedback]);

    // useEffect(() => {
    //     if (!isLoading && !user) {
    //         navigate('/welcome');
    //     }
    // }, [isLoading, user, navigate]);

    // useEffect(() => {
    //     if (id) {
    //         if (timeoutId) {
    //             clearTimeout(timeoutId);
    //         }
    //         const newSetTimeoutId = setTimeout(synchronize, 500);
    //         setTimeoutId(newSetTimeoutId);
    //     }
    // }, [score, energy, id]);

    // if (isLoading) {
    //     // tg.ready();
    //     // tg.expand();
    //     return <div>Loading....</div>;
    // }

    const getClickLevel = () => {
        if (isTurboRunning) {
            return 10 * clickLevel;
        }

        return clickLevel;
    }

    const incrementScore = (length) => {
        modifyScore((getClickLevel() * length))
        if (!isTurboRunning) {
            modifyEnergy(-(clickLevel * length))
        }
    };

    const handleMultiTouch = (event) => {
        if (energy < clickLevel) {
            return;
        }

        const touches = event.touches;
        const popupPositions = [];

        if (energy >= (clickLevel * touches.length)) {
            incrementScore(touches.length);
        } else {
            modifyScore(energy);
            modifyEnergy(-energy);
        }

        for (let i = 0; i < touches.length; i++) {
            if (energy >= clickLevel) {
                if (hapticFeedback) {
                    const randomFeedbackType = getRandomHapticFeedback();
                    hapticFeedback.impactOccurred(randomFeedbackType)
                }

                const touch = touches[i];
                popupPositions.push({
                    x: touch.clientX,
                    y: touch.clientY - 55
                });
            }

            const coinElement = document.querySelector('.coin');
            coinElement.classList.add('shake');

            const timeoutId = setTimeout(() => {
                coinElement.classList.remove('shake');
            }, 500);

            timeoutRefs.current.push(timeoutId);
        }

        const newPopups = getPopups(popupPositions, getClickLevel());
        setPopups(prevPopups => [...prevPopups, ...newPopups]);

        setTimeout(() => {
            setPopups(prevPopups => prevPopups.slice(touches.length));
        }, 1000);
    }

    const handleTurboCoinClick = (addScore) => {
        if (energy >= clickLevel) {
            modifyScore(addScore);
            
            if (hapticFeedback) {
                const randomFeedbackType = getRandomHapticFeedback();
                hapticFeedback.impactOccurred(randomFeedbackType);
            }
        }
    };

    const generateRandomPosition = () => {
        setFlipCoinPosition({
            top: Math.random() * 80 + 10,
            left: Math.random() * 80 + 10
        });
    };

    const handleFlipCoinClick = (isSuccess) => {
        if (isSuccess) {
            startTurbo(20);
        }
    };

    return (
        <div className="page">
            <div className="home-content">
                <div className="top-elements">
                    <div className="league overlay-element">{username}</div>
                    <Score score={score} className="overlay-element" />
                    <League league={league} className="overlay-element" />
                </div>
                <div className="main-content">
                    <div className="coin-container">
                        <Coin
                            energy={energy}
                            clickLevel={clickLevel}
                            handleMultiTouch={handleMultiTouch}
                            coinRef={coinRef}
                            showFlipCoin={showFlipCoin}
                        />
                        {showFlipCoin && (
                            <FlipCoin
                                className="flip-coin"
                                onClick={handleFlipCoinClick}
                                top={flipCoinPosition.top}
                                left={flipCoinPosition.left}
                                flipLevel={flipLevel}
                            />
                        )}
                    </div>
                    <Popups popups={popups}/>
                </div>
                <div className={`${isRunning ? 'inactive' : ''}`}>
                    <Energy
                        energy={energy}
                        energyLevel={energyLevel}
                        setEnergy={setEnergy}
                        energyRecoveryLevel={energyRecoveryLevel}
                        className="overlay-element"
                    />
                </div>
            </div>
            
            <div className={`bottom-elements menu-container ${isRunning ? 'inactive' : ''}`}>
                <Menu />
            </div>
        </div>
    );
}

export default Home;